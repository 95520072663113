<template>
	<div class="systemInfo gradient-background">
		<h1>Diagnostic Data</h1>
		<div class="diagnostics">
			<p><strong>Environment Variables:</strong> {{ environmentVariables }}</p>
			<p><strong>isLoggedOn:</strong> {{ isLoggedOn }}</p>
			<p><strong>username:</strong> {{ username }}</p>
			<p><strong>displayName:</strong> {{ displayName }}</p>
			<p><strong>userId:</strong> {{ userId }}</p>
			<p><strong>isCashier:</strong> {{ isCashier }}</p>
			<p><strong>isReporter:</strong> {{ isReporter }}</p>
			<p><strong>isSiteAdmin:</strong> {{ isSiteAdmin }}</p>
			<p><strong>casinoId:</strong> {{ casinoId }}</p>
			<p><strong>casinoName:</strong> {{ casinoName }}</p>
			<p><strong>Open currentBank:</strong> {{ currentBank }}</p>
			<p><strong>userPermissions:</strong> {{ userPermissions }}</p>
			<p><strong>accessToken:</strong> {{ accessToken }}</p>
			<p><strong>accessTokenExpiration:</strong> {{ accessTokenExpiration }}</p>
			<p><strong>refreshToken:</strong> {{ refreshToken }}</p>
			<p><strong>activeSession:</strong> {{ activeSession }}</p>
			<p><strong>casinoList:</strong> {{ JSON.stringify(casinoList, null, "\t") }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "SystemInfo",
	props: {
		cashierState: {
			type: Object,
		},
		casinoList: {
			type: Array,
		},
	},
	data() {
		return {
			currentBank: JSON.stringify(this.cashierState.currentBank, null, "\t"),
			isLoggedOn: this.cashierState.isLoggedOn,
			casinoName: this.cashierState.casinoName,
			sitePermissions: this.cashierState.sitePermissions,
			isCashier: this.cashierState.isCashier,
			isReporter: this.cashierState.isReporter,
			isSiteAdmin: this.cashierState.isSiteAdmin,
			casinoId: this.cashierState.casinoId,
			displayName: this.cashierState.displayName,
			accessToken: this.cashierState.accessToken,
			accessTokenExpiration: this.cashierState.accessTokenExpiration,
			refreshToken: this.cashierState.refreshToken,
			userPermissions: JSON.stringify(this.cashierState.userPermissions, null, "\t"),
			username: this.cashierState.username,
			userId: this.cashierState.userId,
			activeSession: JSON.stringify(this.cashierState, null, "\t"),
			environmentVariables: process.env,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	overflow: hidden;
}
h1 {
	text-align: center;
}
.systemInfo {
	position: relative;
	margin-left: 20%;
	padding: 15px 0;
	overflow: hidden auto;
	height: calc(100vh - 160px);
}
.diagnostics {
	/* position: absolute; */
	display: flex;
	flex-flow: column;
	/* top: 0; */
	width: 95%;
	margin: 15px auto 20em;
	padding: 30px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 8px;
}
.diagnostics p,
.diagnostics pre {
	font-family: "Courier New", monospace;
	overflow-wrap: anywhere;
	white-space: pre-wrap;
}
</style>
